var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isStarted)?_c('div',{staticClass:"contents"},[_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-lg font-bold"},[_vm._v("Bem-vindos a esta paragem!")]),_c('p',{staticClass:"mb-4"},[_vm._v(" Para receberem saquetas com cromos, terão de responder a "+_vm._s(_vm.questions.length)+" perguntas. ")]),_c('p',{staticClass:"flex items-center justify-center gap-1 font-medium"},[_vm._v(" 1 resposta certa "),_c('material-symbol',{staticClass:"text-yellow-500",attrs:{"size":"20"}},[_vm._v(" arrow_right_alt ")]),_vm._v(" 1 saqueta ")],1),(_vm.availableBags > 1)?_c('p',{staticClass:"flex items-center justify-center gap-1 font-medium"},[_vm._v(" "+_vm._s(_vm.questions.length)+" respostas certas "),_c('material-symbol',{staticClass:"text-yellow-500",attrs:{"size":"20"}},[_vm._v(" arrow_right_alt ")]),_vm._v(" "+_vm._s(_vm.availableBags)+" saquetas ")],1):_vm._e(),_c('p',{staticClass:"mt-4 text-sm text-gray-400"},[_vm._v(" Receberão "+_vm._s(_vm.coins)+" moedas independentemente das respostas. ")])]),_c('div',{staticClass:"self-end"},[_c('Button',{staticClass:"mx-auto mt-8",attrs:{"primary":""},on:{"click":_vm.start}},[_vm._v("Começar")])],1)]):_c('div',{staticClass:"contents"},[_c('transition',{attrs:{"enter-class":"translate-x-1/2 opacity-0","leave-to-class":"-translate-x-1/2 opacity-0"}},[_c('div',{key:_vm.currentQuestionIdx,staticClass:"col-start-1 row-start-2 flex flex-col transition-all duration-300"},[(
          _vm.currentQuestion.attributes.image &&
          _vm.currentQuestion.attributes.image.data
        )?_c('img',{staticClass:"w-100 mb-2 aspect-[16/9] rounded-lg object-cover object-center",attrs:{"src":_vm.currentQuestion.attributes.image.data.attributes.url,"alt":""}}):_vm._e(),_c('p',{staticClass:"mb-4 rounded-lg bg-yellow-200 py-3 px-4 text-center text-lg font-bold text-yellow-800"},[_vm._v(" "+_vm._s(_vm.currentQuestion.attributes.question)+" ")]),_vm._l((_vm.answers),function(answer){return _c('button',{key:answer.id,class:[
          'mb-2 min-h-[2em] rounded-full border border-yellow-400 py-2 px-4 text-center font-medium transition-all duration-300',
          {
            'border-2 border-green-500 text-green-500':
              _vm.chosenAnswer && answer.correct,
            'scale-90 border-red-500': _vm.chosenAnswer && !answer.correct,
            'font-bold': _vm.chosenAnswer && _vm.chosenAnswer.id === answer.id,
          } ],attrs:{"primary":answer.correct && _vm.chosenAnswer !== null},on:{"click":function($event){return _vm.onAnswer(answer)}}},[_vm._v(" "+_vm._s(answer.text)+" ")])})],2)]),_c('div',{staticClass:"row-start-3 self-end"},[_c('p',{staticClass:"mb-3 text-center text-sm font-medium text-gray-600"},[_vm._v(" Pergunta "+_vm._s(_vm.currentQuestionIdx + 1)+" de "+_vm._s(_vm.questions.length)+" ")]),_c('div',{staticClass:"relative mt-12 h-3 w-full rounded-full bg-gray-200"},[_c('div',{staticClass:"absolute top-0 bottom-0 left-0 rounded-full bg-green-500 transition-all duration-300",style:({
          width: ((Math.round((_vm.correctAnswers * 100) / _vm.questions.length)) + "%"),
        })}),_c('div',{class:[
          'absolute -translate-y-[130%] -translate-x-full border-r border-gray-300 pr-1 pb-3 text-right text-xs font-medium leading-none',
          'transition-colors delay-300 duration-200',
          _vm.correctAnswers > 0 ? 'text-green-600' : 'text-gray-500' ],style:({ left: ((Math.round(100 / _vm.questions.length)) + "%") })},[_vm._v(" 1 saqueta ")]),(_vm.availableBags > 1)?_c('div',{class:[
          'absolute right-0 -translate-y-[130%] border-r border-gray-300 pr-1 pb-3 text-right text-xs font-medium leading-none',
          'transition-colors delay-300 duration-200',
          _vm.correctAnswers === _vm.questions.length
            ? 'text-green-600'
            : 'text-gray-500' ]},[_vm._v(" "+_vm._s(_vm.availableBags)+" saquetas ")]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }