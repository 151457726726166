
























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Entity, Question, QuizAnswer } from "@/interfaces";
import Button from "@/components/Button.vue";
import { shuffleArray } from "@/utils";
import MaterialSymbol from "@/components/MaterialSymbol.vue";
@Component({
  components: { MaterialSymbol, Button },
})
export default class QuestionsView extends Vue {
  @Prop({ type: Array, required: true })
  readonly questions!: Entity<Question>[];

  @Prop(Number) readonly availableBags!: number;
  @Prop(Number) readonly coins!: number;

  isStarted = false;
  currentQuestionIdx = 0;
  chosenAnswer: QuizAnswer | null = null;
  correctAnswers = 0;

  start() {
    this.isStarted = true;
  }

  get currentQuestion(): Entity<Question> {
    return this.questions[this.currentQuestionIdx];
  }

  get answers(): QuizAnswer[] {
    if (!this.currentQuestion) return [];
    const answers = this.currentQuestion.attributes.answers;
    return this.currentQuestion.attributes.shuffleAnswers
      ? shuffleArray(answers)
      : answers;
  }

  onAnswer(answer: QuizAnswer) {
    this.chosenAnswer = answer;
    if (answer.correct) this.correctAnswers += 1;

    setTimeout(() => this.nextQuestion(), 3000);
  }

  nextQuestion() {
    const newQuestionIdx = this.currentQuestionIdx + 1;

    if (newQuestionIdx >= this.questions.length) {
      // End
      this.$emit("done", this.correctAnswers);
    } else {
      this.chosenAnswer = null;
      this.currentQuestionIdx += 1;
    }
  }
}
