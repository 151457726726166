import Vue from "vue";
import Vuex from "vuex";
import mapStore, { MapState } from "@/store/map";
import authStore, { AuthState } from "@/store/auth";
import gameStore, { GameState } from "@/store/game";

Vue.use(Vuex);

export interface State {
  map: MapState;
  auth: AuthState;
  game: GameState;
}

export default new Vuex.Store<State>({
  modules: {
    map: mapStore,
    auth: authStore,
    game: gameStore,
  },
});
