



























import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Entity, Sticker, User } from "@/interfaces";
import MaterialSymbol from "@/components/MaterialSymbol.vue";

const authModule = namespace("auth");
const gameModule = namespace("game");
@Component({
  components: { MaterialSymbol },
})
export default class HUD extends Vue {
  @authModule.State readonly user!: User;
  @gameModule.Getter readonly stickers!: Entity<Sticker>[];

  get completion() {
    return Math.round(
      ([...new Set(this.user.ownedStickers)].length * 100) /
        this.stickers.length
    );
  }
}
