import { Module } from "vuex";
import { CollectionResponse, Entity, Stop, Visit } from "@/interfaces";
import strapi from "@/services/strapi";

export interface MapState {
  position: GeolocationPosition | undefined;
  stops: Entity<Stop>[] | undefined;
}

const mapStore: Module<MapState, any> = {
  namespaced: true,
  state: () => ({
    position: undefined,
    stops: undefined,
  }),
  mutations: {
    setPosition(state, position: GeolocationPosition) {
      state.position = position;
    },
    setStops(state, stops: Entity<Stop>[]) {
      state.stops = stops;
    },
  },
  actions: {
    async fetchStops({ commit }) {
      const response = await strapi.get<CollectionResponse<Stop>>(
        `stops?populate=*&pagination[limit]=-1`
      );
      commit("setStops", response.data.data);
    },
  },
};

export default mapStore;
