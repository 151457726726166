import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MapView from "../views/MapView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "map",
    component: MapView,
  },
  {
    path: "/album",
    name: "album",
    component: () =>
      import(/* webpackChunkName: "album" */ "../views/AlbumView.vue"),
  },
  {
    path: "/store",
    name: "store",
    component: () =>
      import(/* webpackChunkName: "store" */ "../views/StoreView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
