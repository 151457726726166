






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MaterialSymbol extends Vue {
  @Prop({ type: Boolean, default: false }) readonly filled!: boolean;
  @Prop({ type: Number, default: 24 }) readonly size!: 20 | 24 | 40 | 48;
  @Prop({ type: Number, default: 400 }) readonly weight!: number;

  get style() {
    return {
      "--msr-fill": this.filled ? 1 : 0,
      "--msr-size": this.size,
      "--msr-weight": this.weight,
      "font-size": `${this.size}px`,
    };
  }
}
