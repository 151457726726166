












import { Component, Vue } from "vue-property-decorator";
import Input from "@/components/Input.vue";
import Button from "@/components/Button.vue";
import { namespace } from "vuex-class";

const authModule = namespace("auth");

@Component({
  components: { Button, Input },
})
export default class LoginView extends Vue {
  @authModule.Action login!: (payload: {
    identifier: string;
    password: string;
  }) => Promise<void>;

  identifier = "";
  password = "";

  onLogin() {
    this.login({ identifier: this.identifier, password: this.password });
  }
}
