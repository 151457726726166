


















import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import "mapbox-gl/dist/mapbox-gl.css";

import mapboxgl, { Marker, LngLat } from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"
import SunCalc from "suncalc";
import { namespace } from "vuex-class";
import MaterialSymbol from "@/components/MaterialSymbol.vue";
import { Entity, Stop, Visit } from "@/interfaces";
import MapStop from "@/components/map/MapStop.vue";
import HUD from "@/components/map/HUD.vue";

mapboxgl.accessToken =
  "pk.eyJ1IjoianBtbWwiLCJhIjoiRzVwdnlHbyJ9.blpnI_MpibsUrqtdQoMLWw";

function DOMCreate(
  tagName: string,
  className?: string,
  container?: HTMLElement
): HTMLElement {
  const el = window.document.createElement(tagName);
  if (className !== undefined) el.className = className;
  if (container) container.appendChild(el);
  return el;
}

const mapModule = namespace("map");
const gameModule = namespace("game");

@Component({
  components: { HUD, MapStop, MaterialSymbol },
})
export default class MapView extends Vue {
  @Ref() readonly mapContainer!: HTMLDivElement;

  // eslint-disable-next-line no-undef
  @mapModule.State readonly position!: GeolocationPosition | undefined;
  @mapModule.State readonly stops!: Entity<Stop>[] | undefined;
  @mapModule.Mutation readonly setPosition!: (
    // eslint-disable-next-line no-undef
    position: GeolocationPosition
  ) => void;

  @gameModule.State readonly visits!: Entity<Visit>[] | undefined;
  @gameModule.Getter readonly hasVisitedStop!: (stopId: number) => boolean;

  map: mapboxgl.Map | null = null;
  markers: { [stopId: number]: Marker } = {};
  permission: boolean | null = null;

  _dotElement: HTMLElement | null = null;
  _userLocationDotMarker: Marker | null = null;
  _circleElement: HTMLElement | null = null;
  _accuracyCircleMarker: Marker | null = null;

  geolocationWatchId: number | null = null;
  heading: number | null = null;

  openStopId: number | null = null;

  get openStop(): Entity<Stop> | undefined {
    return this.stops?.find((stop) => stop.id === this.openStopId);
  }

  get sunPosition() {
    if (!this.map) return [90, 0];
    const center = this.map.getCenter();
    const sunPos = SunCalc.getPosition(new Date(), center.lat, center.lng);
    const sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI;
    const sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI;
    return [sunAzimuth, sunAltitude];
  }

  get isFollowingUser() {
    return !this.openStop;
  }

  async initMap() {
    if (this.map) return;

    await this.$nextTick();

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/jpmml/cl4nyrmzw003h14l5ff387g62", // style URL
      center: this.position
        ? [this.position.coords.longitude, this.position.coords.latitude]
        : [-9.05492, 38.6472], // starting position [lng, lat]
      zoom: 17, // starting zoom
      minZoom: 12,
      maxZoom: 19,
      pitch: 70,
      attributionControl: false,
      boxZoom: false,
      doubleClickZoom: false,
      dragPan: false,
      dragRotate: false,
      keyboard: false,
      scrollZoom: {
        around: "center",
      },
      touchPitch: false,
      touchZoomRotate: {
        around: "center",
      },
      localFontFamily: "Barlow",
    });

    this.map.setPadding({
      top: Math.floor(this.mapContainer.clientHeight * 0.3),
      bottom: 0,
      left: 0,
      right: 0,
    });

    await this.map.once("load");

    this.setupUserMarker();

    this.addStops();
  }

  addStops() {
    if (!this.stops) return;

    this.stops.forEach((stop) => {
      const marker = new Marker({
        color: this.hasVisitedStop(stop.id) ? "#9ca3af" : "#eab308",
      })
        .setLngLat([
          parseFloat(stop.attributes.position.longitude),
          parseFloat(stop.attributes.position.latitude),
        ])
        .addTo(this.map!);
      marker.getElement().classList.add("z-10");
      marker.getElement().addEventListener("click", () => {
        this.openStopId = stop.id;
      });
      this.markers[stop.id] = marker;
    });
  }

  setupUserMarker() {
    this._dotElement = DOMCreate("div", "mapboxgl-user-location");
    this._dotElement.appendChild(
      DOMCreate("div", "mapboxgl-user-location-dot")
    );
    this._dotElement.appendChild(
      DOMCreate("div", "mapboxgl-user-location-heading")
    );

    this._userLocationDotMarker = new Marker({
      element: this._dotElement,
      rotationAlignment: "map",
      pitchAlignment: "map",
    });

    this._circleElement = DOMCreate(
      "div",
      "mapboxgl-user-location-accuracy-circle"
    );
    this._accuracyCircleMarker = new Marker({
      element: this._circleElement,
      pitchAlignment: "map",
    });

    this.map!.on("zoom", this.updateCircleRadius);

    if (this.geolocationWatchId)
      navigator.geolocation.clearWatch(this.geolocationWatchId);
    this.geolocationWatchId = navigator.geolocation.watchPosition(
      (position) => {
        this.setPosition(position);
        this.updateCameraFromPosition();
      },
      () => {
        // Error, do something
      },
      { enableHighAccuracy: true }
    );

    this.addDeviceOrientationListener();
  }

  updateCameraFromPosition() {
    if (!this.map || !this.position) return;

    const center = new LngLat(
      this.position.coords.longitude,
      this.position.coords.latitude
    );
    this._accuracyCircleMarker!.setLngLat(center).addTo(this.map);
    this._userLocationDotMarker!.setLngLat(center).addTo(this.map);

    this.updateCircleRadius();

    if (this.isFollowingUser) {
      this.map.easeTo({
        animate: true,
        center,
      });
    }
  }

  updateCircleRadius() {
    if (!this.position) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tr = this.map.transform;

    const pixelsPerMeter =
      (1.0 /
        (2 *
          Math.PI *
          6371008.8 *
          Math.cos((tr._center.lat * Math.PI) / 180))) *
      tr.worldSize;

    const circleDiameter = Math.ceil(
      2.0 * this.position.coords.accuracy * pixelsPerMeter
    );

    this._circleElement!.style.width = `${circleDiameter}px`;
    this._circleElement!.style.height = `${circleDiameter}px`;
  }

  onDeviceOrientation(deviceOrientationEvent: DeviceOrientationEvent) {
    // absolute is true if the orientation data is provided as the difference between the Earth's coordinate frame and the device's coordinate frame, or false if the orientation data is being provided in reference to some arbitrary, device-determined coordinate frame.
    if (this._userLocationDotMarker) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (deviceOrientationEvent.webkitCompassHeading) {
        // Safari
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.heading = deviceOrientationEvent.webkitCompassHeading;
      } else if (
        deviceOrientationEvent.absolute === true &&
        deviceOrientationEvent.alpha !== null
      ) {
        // non-Safari alpha increases counter clockwise around the z axis
        this.heading = deviceOrientationEvent.alpha * -1;
      }
      this.updateMarkerRotation();
    }
  }

  updateMarkerRotation() {
    if (this._userLocationDotMarker && typeof this.heading === "number") {
      this._userLocationDotMarker.setRotation(this.heading);
      this._dotElement!.classList.add("mapboxgl-user-location-show-heading");
    } else {
      this._dotElement!.classList.remove("mapboxgl-user-location-show-heading");
      this._userLocationDotMarker!.setRotation(0);
    }
  }

  addDeviceOrientationListener() {
    if ("ondeviceorientationabsolute" in window) {
      window.addEventListener("deviceorientationabsolute", (event) =>
        this.onDeviceOrientation(event as DeviceOrientationEvent)
      );
    } else {
      window.addEventListener("deviceorientation", this.onDeviceOrientation);
    }
  }

  // eslint-disable-next-line no-undef
  actOnPermissionState(state: PermissionState) {
    if (state === "granted") {
      this.permission = true;
      this.initMap();
    } else if (state === "prompt") {
      navigator.geolocation.getCurrentPosition(
        () => {
          this.permission = true;
          this.initMap();
        },
        () => {
          this.permission = false;
        },
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      this.permission = false;
    }
  }

  async checkPermissions() {
    const permissionResult = await navigator.permissions.query({
      name: "geolocation",
    });

    this.actOnPermissionState(permissionResult.state);

    permissionResult.addEventListener("change", () => {
      this.actOnPermissionState(permissionResult.state);
    });
  }

  mounted() {
    this.checkPermissions();
  }

  @Watch("openStop")
  onOpenStopChange() {
    if (!this.map) return;

    if (this.openStop) {
      this.map.flyTo({
        animate: true,
        center: [
          parseFloat(this.openStop.attributes.position.longitude),
          parseFloat(this.openStop.attributes.position.latitude),
        ],
        zoom: 18,
        bearing: Math.floor(Math.random() * 359 + 1),
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      });
    } else {
      if (this.position) {
        this.map.easeTo({
          animate: true,
          center: [
            this.position.coords.longitude,
            this.position.coords.latitude,
          ],
          bearing: 0,
          zoom: 17,
          padding: {
            top: Math.floor(this.mapContainer.clientHeight * 0.3),
            bottom: 0,
            left: 0,
            right: 0,
          },
        });
      }
    }
  }

  @Watch("visits")
  onVisitsChange() {
    if (this.visits) {
      for (const stopId in this.markers) {
        const path = this.markers[stopId].getElement().querySelector("path");
        path!.setAttribute(
          "fill",
          this.hasVisitedStop(stopId as unknown as number)
            ? "#9ca3af"
            : "#eab308"
        );
      }
    }
  }
}
