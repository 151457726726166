








import { Component, Vue } from "vue-property-decorator";
import MaterialSymbol from "@/components/MaterialSymbol.vue";
import BottomNavLink from "@/components/bottom-nav/BottomNavLink.vue";
@Component({
  components: { BottomNavLink, MaterialSymbol },
})
export default class BottomNav extends Vue {}
