






















































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Entity, Game, Stop, User, Visit } from "@/interfaces";
import { namespace } from "vuex-class";
import Spinner from "@/components/Spinner.vue";
import MaterialSymbol from "@/components/MaterialSymbol.vue";
import { LngLat } from "mapbox-gl";
import Button from "@/components/Button.vue";
import StopOverlay from "@/components/map/StopOverlay.vue";

const authModule = namespace("auth");
const mapModule = namespace("map");
const gameModule = namespace("game");

@Component({
  components: { StopOverlay, Button, MaterialSymbol, Spinner },
})
export default class MapStop extends Vue {
  @Prop({ type: Object, required: true }) readonly stop!: Entity<Stop>;

  isLoading = false;

  isVisiting = false;

  @authModule.State readonly user!: User;
  @gameModule.State readonly visits!: Entity<Visit>[] | undefined;
  // eslint-disable-next-line no-undef
  @mapModule.State readonly position: GeolocationPosition | undefined;
  @gameModule.Action readonly fetchVisits!: () => Promise<void>;

  @gameModule.State readonly game!: Entity<Game>;
  @gameModule.Getter readonly hasVisitedStop!: (stopId: number) => boolean;

  async created() {
    this.isLoading = true;
    await this.fetchVisits();
    this.isLoading = false;
  }

  get stopVisits(): Entity<Visit>[] {
    if (!this.visits) return [];
    return this.visits.filter(
      (v) => v.attributes.stop.data.id === this.stop.id
    );
  }

  get hasVisited(): boolean {
    return this.hasVisitedStop(this.stop.id);
  }

  get remainingBags(): number {
    if (!this.visits) return 0;
    return (
      this.game.attributes.bagsPerStop -
      this.stopVisits.reduce(
        (prev, visit) => prev + visit.attributes.earnedBags,
        0
      )
    );
  }

  get distanceTo(): number | undefined {
    if (!this.position) return undefined;
    const stop = new LngLat(
      parseFloat(this.stop.attributes.position.longitude),
      parseFloat(this.stop.attributes.position.latitude)
    );
    const position = new LngLat(
      this.position.coords.longitude,
      this.position.coords.latitude
    );
    return stop.distanceTo(position);
  }

  get canVisit(): boolean {
    return this.distanceTo !== undefined && this.distanceTo <= 20;
  }

  @Emit()
  close() {
    // Just emit
  }
}
