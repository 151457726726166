






























































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  Entity,
  Game,
  Question,
  Sticker,
  Stop,
  User,
  Visit,
} from "@/interfaces";
import { namespace } from "vuex-class";
import Spinner from "@/components/Spinner.vue";
import QuestionsView from "@/components/map/QuestionsView.vue";
import Button from "@/components/Button.vue";
import strapi from "@/services/strapi";
import { shuffleArray } from "@/utils";
import StickerCard from "@/components/album/StickerCard.vue";

const authModule = namespace("auth");
const gameModule = namespace("game");
@Component({
  components: { StickerCard, Button, QuestionsView, Spinner },
})
export default class StopOverlay extends Vue {
  @authModule.State readonly user!: User;
  @authModule.Action readonly fetchUser!: () => Promise<void>;

  @gameModule.State readonly game!: Entity<Game>;
  @gameModule.State readonly visits!: Entity<Visit>[] | undefined;
  @gameModule.Getter readonly stickers!: Entity<Sticker>[];
  @gameModule.Action readonly getQuestions!: (
    count: number
  ) => Promise<Entity<Question>[]>;
  @gameModule.Action readonly fetchVisits!: () => Promise<void>;

  @Prop({ type: Object, required: true }) readonly stop!: Entity<Stop>;

  isLoading = false;

  questions: Entity<Question>[] | null = null;
  correctAnswers: number | null = null;

  obtainedBags: Entity<Sticker>[][] | null = null;

  get stopVisits(): Entity<Visit>[] {
    if (!this.visits) return [];
    return this.visits.filter(
      (v) => v.attributes.stop.data.id === this.stop.id
    );
  }

  get remainingBags(): number {
    if (!this.visits) return 0;
    return (
      this.game.attributes.bagsPerStop -
      this.stopVisits.reduce(
        (prev, visit) => prev + visit.attributes.earnedBags,
        0
      )
    );
  }

  async created() {
    this.isLoading = true;
    await this.fetchVisits();
    this.questions = await this.getQuestions(
      this.remainingBags > 1 ? this.game.attributes.questionsPerStop : 1
    );
    this.isLoading = false;

    if (this.remainingBags === 0) {
      this.registerVisit();
    }
  }

  onQuestionsDone(correctAnswers: number) {
    this.correctAnswers = correctAnswers;
    this.registerVisit();
  }

  get earnedBags(): number {
    if (!this.correctAnswers) return 0;
    if (this.correctAnswers >= this.game.attributes.questionsPerStop)
      return this.game.attributes.bagsPerStop;
    return 1;
  }

  async registerVisit() {
    this.isLoading = true;
    // Get stickers
    this.obtainedBags = Array.from({ length: this.earnedBags }, () =>
      shuffleArray(this.stickers).slice(0, this.game.attributes.stickersPerBag)
    );

    // Add coins and stickers
    await strapi.put(`users/${this.user.id}`, {
      ...this.user,
      coins: this.user.coins + this.game.attributes.coinsPerStop,
      ownedStickers: [
        ...(this.user.ownedStickers || []),
        ...this.obtainedBags.flat().map((s) => s.id),
      ],
    });

    // Create visit
    await strapi.post("visits", {
      data: {
        user: this.user.id,
        stop: this.stop.id,
        questions: this.questions?.map((q) => q.id) || [],
        earnedBags: this.earnedBags,
      },
    });

    this.isLoading = false;

    this.fetchVisits();
    this.fetchUser();
  }
}
