












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Input extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(String) readonly value!: string;
  @Prop({ type: String, default: "text" }) readonly type!: string;
}
