var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{class:[
      'flex flex-col items-center py-3',
      'transition duration-300',
      (_vm.exact ? isExactActive : isActive)
        ? 'text-stone-900'
        : 'text-yellow-900' ],attrs:{"href":href},on:{"click":navigate}},[_c('p',{class:[
        'relative h-6 w-6',
        'before:transition-all before:duration-300',
        'before:absolute before:top-1/2 before:left-1/2 before:h-8 before:-translate-y-1/2 before:-translate-x-1/2 before:rounded-full before:bg-yellow-300 before:content-[\'\']',
        (_vm.exact ? isExactActive : isActive)
          ? 'before:w-14'
          : 'before:w-8 before:opacity-0' ]},[_c('material-symbol',{staticClass:"relative duration-200",attrs:{"filled":_vm.exact ? isExactActive : isActive}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('p',{class:['text-xs', 'font-medium', 'mt-1']},[_vm._t("default")],2)])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }