import { Module } from "vuex";
import {
  Album,
  CollectionResponse,
  Entity,
  Game,
  Question,
  SingleResponse,
  Sticker,
  Stop,
  Visit,
} from "@/interfaces";
import strapi from "@/services/strapi";
import { State } from "@/store/index";
import { shuffleArray } from "@/utils";

export interface GameState {
  game: Entity<Game> | undefined;
  album: Entity<Album> | undefined;
  visits: Entity<Visit>[] | undefined;
  allQuestions: Entity<Question>[] | undefined;
}

const gameStore: Module<GameState, State> = {
  namespaced: true,
  state: () => ({
    game: undefined,
    album: undefined,
    visits: undefined,
    allQuestions: undefined,
  }),
  getters: {
    stickers: (state) => {
      if (!state.album) return [];
      return state.album.attributes.pages.reduce<Entity<Sticker>[]>(
        (acc, page) => {
          const newAcc = [...acc];
          for (const sticker of page.stickers) {
            if (!newAcc.some((st) => st.id === sticker.sticker.data.id)) {
              newAcc.push(sticker.sticker.data);
            }
          }
          return newAcc;
        },
        []
      );
    },
    hasVisitedStop: (state, getters, rootState) => (stopId: number) => {
      if (!state.visits || !rootState.auth.user) return false;
      return state.visits.some(
        (v) =>
          v.attributes.stop.data.id === stopId &&
          v.attributes.user.data.id === rootState.auth.user!.id
      );
    },
    answeredQuestions: (state, getters, rootState) => {
      if (!state.visits || !rootState.auth.user) return [];
      const userVisits = state.visits.filter(
        (v) => v.attributes.user.data.id === rootState.auth.user!.id
      );
      return userVisits.reduce<Entity<Question>[]>(
        (acc, visit) => [...acc, ...visit.attributes.questions.data],
        []
      );
    },
    unansweredQuestion: (state, getters) => {
      if (!state.allQuestions) return [];
      const answeredIds = getters.answeredQuestions.map(
        (q: Entity<Question>) => q.id
      );
      return state.allQuestions.filter((q) => !answeredIds.includes(q.id));
    },
  },
  mutations: {
    setGame(state, game: Entity<Game>) {
      state.game = game;
    },
    setAlbum(state, album: Entity<Album>) {
      state.album = album;
    },
    setVisits(state, visits: Entity<Visit>[]) {
      state.visits = visits;
    },
    setQuestions(state, questions: Entity<Question>[]) {
      state.allQuestions = questions;
    },
  },
  actions: {
    async fetchGame({ commit }) {
      const response = await strapi.get<SingleResponse<Game>>(`game`);
      commit("setGame", response.data.data);
    },
    async fetchAlbum({ commit }) {
      const response = await strapi.get<SingleResponse<Album>>(
        `album?populate=pages.stickers.sticker.image,pages.bgImage`
      );
      commit("setAlbum", response.data.data);
    },
    async fetchVisits({ commit }) {
      const response = await strapi.get<CollectionResponse<Visit>>(
        `visits?populate=*&pagination[limit]=-1`
      );
      commit("setVisits", response.data.data);
    },
    async fetchQuestions({ commit }) {
      const response = await strapi.get<CollectionResponse<Question>>(
        `questions?populate=*&pagination[limit]=-1`
      );
      commit("setQuestions", response.data.data);
    },
    async getQuestions({ getters }, count: number) {
      const shuffledUnanswered = shuffleArray(getters.unansweredQuestion);
      return shuffledUnanswered.slice(0, count);
    },
  },
};

export default gameStore;
