





















import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { Entity, Sticker } from "@/interfaces";

@Component
export default class StickerCard extends Vue {
  @Prop({ type: Object, required: true }) readonly sticker!: Entity<Sticker>;
  @Prop({ type: String }) readonly orientation!:
    | "portrait"
    | "landscape"
    | undefined;
  @Prop({ type: String, required: true }) readonly size!: string;
  @Prop({ type: Boolean, default: true }) readonly owned!: true;

  @Ref() readonly container!: HTMLDivElement;

  containerWidth = 0;
  observer: ResizeObserver | null = null;

  get cardOrientation() {
    return this.orientation || this.sticker.attributes.orientation;
  }

  get containerStyle() {
    if (this.cardOrientation === "portrait") {
      return {
        height: this.size,
        "aspect-ratio": "3 / 4",
      };
    }

    return {
      width: this.size,
      "aspect-ratio": "4 / 3",
    };
  }

  get stickerStyle() {
    const style: Record<string, any> = {};

    if (this.sticker.attributes.image.data) {
      style[
        "background-image"
      ] = `url(${this.sticker.attributes.image.data.attributes.url})`;
    }

    if (this.sticker.attributes.orientation === "portrait") {
      style["aspect-ratio"] = "3 / 4";
      if (this.orientation === "portrait")
        style["height"] = `${this.containerWidth}px`;
      else style["width"] = `${this.containerWidth}px`;
    } else {
      style["aspect-ratio"] = "4 / 3";
      if (this.orientation === "landscape")
        style["height"] = `${this.containerWidth}px`;
      else style["width"] = `${this.containerWidth}px`;
    }

    if (this.cardOrientation !== this.sticker.attributes.orientation) {
      style.transform = "rotate(90deg)";
    }

    return style;
  }

  get notOwnedStyle() {
    const baseWidth =
      this.containerWidth * (this.cardOrientation === "portrait" ? 4 / 3 : 1);
    return {
      "border-width": `${Math.round(baseWidth * 0.02)}px`,
      "font-size": `${Math.round(baseWidth * 0.2)}px`,
    };
  }

  mounted() {
    this.containerWidth = this.container.clientWidth;
    this.observer = new ResizeObserver((entries) => {
      this.containerWidth = entries[0].contentRect.width;
    });
    this.observer.observe(this.container);
  }
}
