import { Module } from "vuex";
import { User } from "@/interfaces";
import strapi from "@/services/strapi";

export interface AuthState {
  jwt: string | null;
  user: User | null;
}

export const STORAGE_JWT_KEY = "rv_jwt";

const authStore: Module<AuthState, any> = {
  namespaced: true,
  state: () => ({
    jwt: localStorage.getItem(STORAGE_JWT_KEY),
    user: null,
  }),
  getters: {
    isLogged: (state) => state.user !== null,
    hasJwt: (state) => state.jwt !== null,
  },
  mutations: {
    setJwt(state, jwt: string) {
      state.jwt = jwt;
      localStorage.setItem(STORAGE_JWT_KEY, jwt);
    },
    clearJwt(state) {
      state.jwt = null;
      localStorage.removeItem(STORAGE_JWT_KEY);
    },
    setUser(state, user: User | null) {
      state.user = user;
    },
  },
  actions: {
    async login({ commit }, payload: { identifier: string; password: string }) {
      const response = await strapi.post<{ jwt: string; user: User }>(
        `auth/local`,
        payload
      );
      commit("setJwt", response.data.jwt);
    },
    async fetchUser({ commit }) {
      const response = await strapi.get<User>(`users/me`);
      commit("setUser", response.data);
    },
    async logout({ commit }) {
      commit("clearJwt");
      commit("setUser", undefined);
    },
  },
};

export default authStore;
