import axios, { AxiosError } from "axios";
import { STORAGE_JWT_KEY } from "@/store/auth";
import store from "@/store";

const strapi = axios.create({
  baseURL: `${process.env.VUE_APP_CMS_URL}/api`,
});

strapi.interceptors.request.use((config) => {
  const jwt = localStorage.getItem(STORAGE_JWT_KEY);
  if (jwt) {
    config.headers = {
      ...(config.headers || {}),
      Authorization: `Bearer ${jwt}`,
    };
  }

  return config;
});

strapi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error && error.code === "401") {
      store.commit("user.clearJwt");
    }

    return Promise.reject(error);
  }
);

export default strapi;
