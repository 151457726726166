import Vue from "vue";
import { VueHammer } from "vue2-hammer";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "@fontsource/barlow/400.css";
import "@fontsource/barlow/500.css";
import "@fontsource/barlow/700.css";
import "material-symbols/rounded.css";

import "@fontsource/londrina-solid/400.css";
import "@fontsource/londrina-solid/900.css";

Vue.config.productionTip = false;

Vue.use(VueHammer);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
