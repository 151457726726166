

































import { Component, Vue, Watch } from "vue-property-decorator";
import BottomNav from "@/components/bottom-nav/BottomNav.vue";
import Button from "@/components/Button.vue";
import { namespace } from "vuex-class";
import { Entity, Stop } from "@/interfaces";
import LoginView from "@/views/LoginView.vue";
import Spinner from "@/components/Spinner.vue";

const authModule = namespace("auth");
const mapModule = namespace("map");
const gameModule = namespace("game");

@Component({
  components: { Spinner, LoginView, Button, BottomNav },
})
export default class App extends Vue {
  @authModule.Getter isLogged!: boolean;
  @authModule.Getter hasJwt!: boolean;
  @authModule.Action readonly fetchUser!: () => Promise<void>;

  @mapModule.Action readonly fetchStops!: () => Promise<void>;
  @gameModule.Action readonly fetchVisits!: () => Promise<void>;

  @gameModule.Action readonly fetchGame!: () => Promise<void>;
  @gameModule.Action readonly fetchAlbum!: () => Promise<void>;
  @gameModule.Action readonly fetchQuestions!: () => Promise<void>;

  refreshing = false;
  registration: ServiceWorkerRegistration | null = null;
  updateExists = false;
  isLoading = true;

  showRefreshUI(e: any): void {
    this.registration = e.detail;
    this.updateExists = false;
  }

  refreshApp(): void {
    this.updateExists = false;
    if (!this.registration || !this.registration.waiting) {
      return;
    }
    this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }

  created(): void {
    document.addEventListener("swUpdated", this.showRefreshUI, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  }

  @Watch("hasJwt", { immediate: true })
  async initGame() {
    if (this.hasJwt) {
      this.isLoading = true;
      await Promise.all([
        this.fetchUser(),
        this.fetchStops(),
        this.fetchVisits(),
        this.fetchAlbum(),
        this.fetchGame(),
        this.fetchQuestions(),
      ]);
      this.isLoading = false;
    }
  }
}
