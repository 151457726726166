






































import { Component, Prop, Vue } from "vue-property-decorator";
import MaterialSymbol from "@/components/MaterialSymbol.vue";
@Component({
  components: { MaterialSymbol },
})
export default class BottomNav extends Vue {
  @Prop(String) readonly to!: string;
  @Prop(String) readonly icon!: string;
  @Prop({ type: Boolean, default: false }) readonly exact!: boolean;
}
